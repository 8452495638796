const UpcomingEventData = [
    {
        img_url:"https://i.imgur.com/Sj7L7gH.png",
        date: {
            day: 12,
            month: 'July',
            time: '12:00 AM - 12:30 AM'
        },
        location: 'Patton U',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
        img_url:"https://i.imgur.com/zqoad05.jpg",
        date: {
            day: 12,
            month: 'July',
            time: '12:00 AM - 12:30 AM'
        },
        location: 'Patton U',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },

];

export default UpcomingEventData;