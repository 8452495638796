import React from 'react';
import Team from '../../components/team/team';
import Overlay from '../../components/overlay/overlay';
import './main.css';
import MissionStatement from '../../components/mission/missionStatement';
import FinalCTA from '../../components/cta/finalCTA';
import Process from '../../components/process/process';
import LogoSlider from '../../components/slider/logoSlider';
import JoinCTA from '../../components/cta/joinCTA';
import DeveventOverview from '../../components/events/devevent';
import Navbar from '../../components/navbar/content';
import Footer from '../../components/footer/footer';

const Developers = () => {
    return (
        <>
            <Navbar />
            <main>
                <Overlay />
                <LogoSlider />
                <MissionStatement />
                <Process />
                <FinalCTA />

                <div className="container marketing">
                    <hr className="featurette-divider" />

                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">Software Engineer:<span className="text-muted"> How To Land on Your First Job</span></h2>
                            <p className="lead">We’ll walk you through everything you need to know about software engineering—we’ll take a look on what does it takes to find a job as a software engineer, what make this profession very demanding nowadays, is it really necessary to take up a four year degree course or software bootcamp will do? </p>
                        </div>
                        <div className="col-md-5">
                            <img src={'https://i.imgur.com/CAv8HUB.jpg'} alt="job" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500px" height="500px" />

                        </div>
                    </div>

                    <hr className="featurette-divider" />

                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading">Oh yeah, it’s that good. <span className="text-muted">See for yourself.</span></h2>
                            <p className="lead">Another featurette? Of course. More placeholder content here to give you an idea of how this layout would work with some actual real-world content in place.</p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee" /><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg>

                        </div>
                    </div>

                    <hr className="featurette-divider" />

                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">And lastly, this one. <span className="text-muted">Checkmate.</span></h2>
                            <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
                        </div>
                        <div className="col-md-5">
                            <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee" /><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg>

                        </div>
                    </div>
                    <hr className="featurette-divider" />
                </div>
                <JoinCTA />
                <DeveventOverview />
                <div className="container marketing">
                    <hr className="featurette-divider" />

                    <Team />
                    <hr className="featurette-divider" />
                </div>
            </main>
            <Footer />
        </>


    )
}

export default Developers;